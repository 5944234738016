import React, { useEffect } from 'react';

const App = () => {
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = "https://drloretta-dev.jennai.com/Jenn.bundle.js";
    script1.defer = true;
    const isMobileScreen = () => window.innerWidth <= 768;
    window.callInitializeJenn = function () {
      if (window.initializeJenn) {
        window.isShopifyMobile = isMobileScreen();
        window.initializeJenn('jenn6d9jz-jenn-container', window.isShopifyMobile);

        window.addEventListener('resize', () => {
          window.isShopifyMobile = isMobileScreen();
        });
      } else {
        console.error("initializeJenn was not defined or loaded properly");
      }      
    };
    
  
    document.body.appendChild(script1);

    return () => {
      document.body.removeChild(script1);
      window.removeEventListener('resize', () => {
        window.isShopifyMobile = isMobileScreen();
      });
    };
  }, []);

  return (
    <div>
      <div id="jenn6d9jz-jenn-container"></div>
    </div>
  );
};

export default App;